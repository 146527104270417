import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  BasicUserResponse,
  OnboardingShowChangePassword,
} from '../models/interfaces';
import { Observable } from 'rxjs';
import { SignalStatesService } from './signal-states.service';
import { UpdateStageService } from './update-stage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl: string = environment.baseUrl;  
  constructor(private signalStatesService: SignalStatesService, private http: HttpClient, private updateStageService: UpdateStageService, private router: Router) {    
  }

  private logout() {
    localStorage.clear();
  }

  public basicUserLogin(token: string): Observable<BasicUserResponse> {
    return this.http.post<BasicUserResponse>(
      `${this.baseUrl}v1/basic_user_person/login/`,
      {
        token: token,
      },
      {
        headers: {
          Channel: 'onboarding',
        },
      }
    );
  }

  public showChangePassword(
    onboarding_id: number,
    authorization: string
  ): Observable<OnboardingShowChangePassword> {
    return this.http.get<OnboardingShowChangePassword>(
      `${this.baseUrl}v1/onboarding/${onboarding_id}/?show_change_password_token=true&show_stages=true`,
      {
        headers: {
          Channel: 'onboarding',
          Authorization: authorization,
        },
      }
    );
  }

  public masterChurch(
    master_church_id: number,
    authorization: string
  ): Observable<any> {
    // const fields = '?fields:id,name,email,location{zip_code,international_zip_code,address,address_number,address_complement,neighborhood,country,state,international_state,city,international_city},resource_uri';
    const fields = '';
    return this.http.get<any>(
      `${this.baseUrl}v2/tertiary_group/${master_church_id}/${fields}`,
      {
        headers: {
          Channel: 'onboarding',
          Authorization: authorization,
        },
      }
    );
  }

  // Define Passwords

  setNewPassword(newPasswordData: { token: string; password: string }): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}v1/basic_user_person/change_password/`, newPasswordData,
       {
        headers: {
          Channel: 'onboarding',
          Authorization: this.signalStatesService.loggedStorageSignal().basic_user_person.authorization,
        }
       }

      );
  }
}
